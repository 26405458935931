<template>
  <div id="formulaire">
    <Menu/>
    <b-container
      class="d-flex justify-content-center align-items-center"
    >
      <b-form-group
        v-if="count === 0"
        class="d-flex justify-content-center align-items-center"
      >
      <h1>Qui es-tu ?</h1>
        <b-row>
          <b-col>
            <b-form-input
              v-model="data.name"
              type="text"
              class="m-2"
              placeholder="Nom*"
              required
            ></b-form-input>
            <b-form-input
              v-model="data.surname"
              type="text"
              class="m-2"
              placeholder="Prénom*"
              required
            ></b-form-input>
            <b-form-input
              v-model="data.email"
              type="email"
              class="m-2"
              placeholder="Email"
            ></b-form-input>
            <b-form-input
              v-model="data.phone"
              type="text"
              class="m-2"
              placeholder="Téléphone"
            ></b-form-input>
          </b-col>
        </b-row>
        <div
          v-if="(data.name && data.surname) && (data.email || data.phone)"
        >
          <b-button
            class="m-2 next"
            @click.stop="next()"
          >
            Suivant
          </b-button>
        </div>
      </b-form-group>
      <b-form-group
        v-if="count === 1"
      >
      <h1>Seras-tu présent lors de notre mariage ?</h1>
        <b-row
          class="d-flex justify-content-center"
        >
          <b-form-radio class="m-2" v-model="data.present" value="1" size="lg">Oui</b-form-radio>
          <b-form-radio class="m-2" v-model="data.present" value="0" size="lg">Non</b-form-radio>
        </b-row>
        <div>
          <b-button
            class="m-2 previous"
            @click.stop="previous()"
          >
            Précedent
          </b-button>
          <b-button
            v-if="data.present === '1'"
            class="m-2 next"
            @click.stop="next()"
          >
            Suivant
          </b-button>
          <b-button
            v-else-if="data.present"
            class="m-2 next"
            @click.stop="confirm()"
          >
            Envoyer
          </b-button>
        </div>
      </b-form-group>
      <b-form-group
        v-if="count === 2"
        class="d-flex justify-content-center align-items-center container-fluid"
      >
        <h1>Seras-tu accompagné ?</h1>
        <b-row
          class="mb-2"
        >
          <b-col sm="12" md="6">
            <p class="w-200">Adulte(s)</p>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-spinbutton class="m-2 w-200" v-model="nbAdult" min="0" max="10"></b-form-spinbutton>
          </b-col>
        </b-row>
        <b-row
          v-for="(person, t) in nbAdult"
          :key="t"
          class="mb-2"
        >
          <b-col sm="12" md="6">
            <b-form-input
              v-model="data.adult.names[t]"
              type="text"
              class="m-2 w-200"
              placeholder="Nom*"
              required
            ></b-form-input>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-input
              v-model="data.adult.surnames[t]"
              type="text"
              class="m-2 w-200"
              placeholder="Prénom*"
              required
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row
          class="mb-2"
        >
          <b-col sm="12" md="6">
            <p class="w-200">Enfant(s)</p>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-spinbutton class="m-2 w-200" v-model="nbChildren" min="0" max="10"></b-form-spinbutton>
          </b-col>
        </b-row>
        <b-row
          v-for="(person, t) in nbChildren"
          :key="t"
          class="mb-2"
        >
          <b-col sm="12" md="4">
            <b-form-input
              v-model="data.children.names[t]"
              type="text"
              class="m-2 w-133"
              placeholder="Nom*"
              required
            ></b-form-input>
          </b-col>
          <b-col sm="12" md="4">
            <b-form-input
              v-model="data.children.surnames[t]"
              type="text"
              class="m-2 w-133"
              placeholder="Prénom*"
              required
            ></b-form-input>
          </b-col>
          <b-col
            sm="12" md="4"
            class="d-flex justify-content-between align-items-center flex-wrap"
          >
            <b-form-input
              v-model="data.children.age[t]"
              type="text"
              class="m-2 w-133"
              placeholder="Âge*"
              required
            ></b-form-input>
          </b-col>
        </b-row>
        <div>
          <b-button
            class="m-2 previous"
            @click.stop="previous()"
          >
            Précedent
          </b-button>
          <b-button
            class="m-2 next"
            @click.stop="next()"
          >
            Suivant
          </b-button>
        </div>
      </b-form-group>
      <b-form-group
        v-if="count === 3"
      >
        <p class="mb-5">Nous avons souhaité que les féstivités <br> durent plus qu'une journée pour prendre le temps, <br><span class="sheLovelyFont">comme en voyage</span>. <br> Vous êtes <span class="sheLovelyFont">libres de choisir</span> les moments <br> auxquels vous souhaitez participer.</p>
      <h1>À quelle(s) activité(s) souhaites-tu participer ?</h1>
        <b-row
          class="d-flex justify-content-center"
        >
          <b-form-checkbox
            class="m-4 big-checkbox"
            v-model="data.activities.town_hall"
            name="checkbox-1"
            size="lg"
            value="1"
            unchecked-value="0"
          >
            <span class="icon">💍</span><br><br>
            <span class="sheLovelyFont">Mairie</span><br>
            <span class="font-size-small">Samedi 4 juin 2022, à 15h<br>
            Lansargues</span>
          </b-form-checkbox>
          <b-form-checkbox
            class="m-4 big-checkbox"
            v-model="data.activities.meal"
            name="checkbox-1"
            size="lg"
            value="1"
            unchecked-value="0"
          >
            <span class="icon">🥂</span><br><br>
            <span class="sheLovelyFont">Diner et soiree</span><br>
            <span class="font-size-small">Samedi 4 juin 2022, à 20h<br>
            Mas de Remoulis, <br>Saint-Julien-de-la-Nef</span>
          </b-form-checkbox>
          <b-form-checkbox
            class="m-4 big-checkbox"
            v-model="data.activities.brunch"
            name="checkbox-1"
            size="lg"
            value="1"
            unchecked-value="0"
          >
            <span class="icon">🥐</span><br><br>
            <span class="sheLovelyFont">Brunch</span><br>
            <span class="font-size-small">Dimanche 5 juin 2022, à 12h<br>
            Mas de Remoulis, <br>Saint-Julien-de-la-Nef</span>
          </b-form-checkbox>
          <b-form-checkbox
            class="m-4 big-checkbox"
            v-model="data.activities.activity"
            name="checkbox-1"
            size="lg"
            value="1"
            unchecked-value="0"
          >
            <span class="icon">🚣‍♀️</span><br><br>
            <span class="sheLovelyFont">Activite a partager</span><br>
            <span class="font-size-small">Lundi 6 juin 2022, à 10h<br>
            Mas de Remoulis, <br>Saint-Julien-de-la-Nef</span>
          </b-form-checkbox>
        </b-row>
        <div>
          <b-button
            class="m-2 previous"
            @click.stop="previous()"
          >
            Précedent
          </b-button>
          <b-button
            class="m-2 next"
            v-if="data.activities.town_hall === '1' || data.activities.meal === '1' || data.activities.brunch === '1' || data.activities.activity === '1'"
            @click.stop="next()"
          >
            Suivant
          </b-button>
        </div>
      </b-form-group> 
      <b-form-group
        v-if="count === 4"
      >
      <h1>As-tu une information importante à nous partager ?</h1>
        <b-row
          class="d-flex justify-content-center"
        >
          <b-form-textarea
            v-model="data.remark"
            placeholder="Allergie alimentaire..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-row>
        <div>
          <b-button
            class="m-2 previous"
            @click.stop="previous()"
          >
            Précedent
          </b-button>
          <b-button
            class="m-2 next"
            @click.stop="next()"
          >
            Suivant
          </b-button>
        </div>
      </b-form-group>
      <b-form-group
        v-if="count === 5"
      >
      <h1 class="mb-4">Si toutes ces informations sont correctes, alors tu peux envoyer !</h1>
        <b-row
          class="d-flex justify-content-center"
        >
        <b-list-group>
          <b-list-group-item><i>Nom</i> : {{ data.name }}</b-list-group-item>
          <b-list-group-item><i>Prénom</i> : {{ data.surname }}</b-list-group-item>
          <b-list-group-item><i>Email</i> : {{ data.email }}</b-list-group-item>
          <b-list-group-item><i>Téléphone</i> : {{ data.phone }}</b-list-group-item>
          <b-list-group-item>Je serai présent au mariage</b-list-group-item>
          <b-list-group-item v-if="data.adult.names.length > 0">Je viendrai avec {{ data.adult.names.length }} adultes</b-list-group-item>
          <b-list-group-item v-if="data.children.names.length > 0">Je viendrai avec : {{ data.children.names.length }} enfants</b-list-group-item>
          <b-list-group-item><i>Activité</i> : {{ data.activities.town_hall === '1' ? 'Mairie' : '' }} {{data.activities.meal === '1' ? 'Dîner et soirée' : ''}} {{data.activities.brunch === '1' ? 'Brunch' : ''}} {{ data.activities.activity  === '1' ? 'Activité' : '' }}</b-list-group-item>
          <b-list-group-item v-if="data.reward">Mon commentaires : {{ data.reward }}</b-list-group-item>
        </b-list-group>
        </b-row>
        <div>
          <b-button
            class="m-2 previous"
            @click.stop="previous()"
          >
            Précedent
          </b-button>
          <b-button
            class="m-2 next"
            @click.stop="confirm()"
          >
            Envoyer
          </b-button>
        </div>
      </b-form-group>
  </b-container>
  </div>
</template>
<script>
import Menu from './../components/Menu.vue'
import axios from 'axios'
export default {
  components: {
    Menu
  },
  data() {
    return {
      nbAdult: 0,
      nbChildren: 0,
      data: {
        name: null,
        surname: null,
        email: null,
        phone: null,
        present: null,
        adult: {
          names: [],
          surnames: [],
        },
        children: {
          names: [],
          surnames: [],
          age: []
        },
        activities: {
          town_hall: 0,
          meal: 0, 
          brunch: 0,
          activity: 0
        },
        remark: null,
      },
      count: 0
    }
  },
  methods: {
    confirm() {
      axios.post(`https://api.notremariage.love/inscription`, {
        name: this.data.name,
        surname: this.data.surname,
        email: this.data.email,
        phone: this.data.phone,
        present: this.data.present,
        adult: this.data.adult,
        children: this.data.children,
        town_hall: this.data.activities.town_hall,
        meal: this.data.activities.meal,
        brunch: this.data.activities.brunch,
        activity: this.data.activities.activity,
        remark: this.data.remark,
      },
      { headers: { "access-token": localStorage.getItem("access-token") }
      }).then(() => {
        this.$router.push("/confirmation");
      });
    },
    next() {
      this.count++;
    },
    previous() {
      this.count--;
    }
  }
}
</script>
